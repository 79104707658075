import Vue from 'vue'

Vue.filter('isActive', function (value) {
  if (value) {
    return 'Active';
  }
  return 'Inactive';

});

Vue.filter('sheetStatus', function (value) {
  switch (value) {
    case 'pending_approval':
      return 'Pending Approval'
    case 'approved':
      return 'Approved';
    case 'completed':
      return 'Completed'
  }
});

Vue.filter('dateDuration', function (value) {
  switch (value) {
    case 1:
      return 'Day';
    case 7:
      return 'Week';
    case 30:
      return 'Month';
    case 365:
      return 'Year';
    default:
      return `${value} Days`
  }
  if (value === 1) {
    return 'Day';
  }
  return 'Inactive';
});

Vue.filter('isDuplicated', function (value) {
  if (value) {
    return 'Yes';
  }
  return 'No';

});

Vue.filter('isMandatory', function (value) {
  if (value) {
    return 'Yes';
  }
  return 'No';

});

Vue.filter('consultationType', function (value) {
  switch (value) {
    case 1:
      return 'Altibbi';
    case 2:
      return 'CoronaVirus';
    case 3:
      return 'Hypertension';
    case 4:
      return 'Outbound';
    case 5:
      return 'Inbound'
  }
});

Vue.filter('ageFromDate', function (value) {
  if (!value) {
    return '';
  }
  let dateOfBirth = new Date(value);
  let ageDifMs = Date.now() - dateOfBirth.getTime();
  let ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
});

Vue.filter('snakeToUpper', function (value) {
  let sentence = value.toLowerCase().replace('_', " ").split(" ");
  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }

  return sentence.join(" ");
});


