export default function (Vue) {
  let current_user = null;
  let user_type = null;
  let partner_name = null;
  let partner_type = null;
  let enabled_features = [];
  let notifications_count = 0;

  Vue.auth = {

    init ({ currentUser, userType, partnerName, partnerType, enabledFeatures, notificationsCount }) {
      current_user = currentUser;
      user_type = userType;
      partner_name = partnerName;
      partner_type = partnerType;
      enabled_features = enabledFeatures;
      notifications_count = notificationsCount;
    },

    setToken (token, expiration) {
      localStorage.setItem('token', token);
      localStorage.setItem('token_expiration', expiration);
    },

    getToken () {
      const token = localStorage.getItem('token');
      const expiration = localStorage.getItem('token_expiration');

      if (!token || !expiration) {
        return null;
      }
      if (Date.now() > expiration) {
        this.destroyToken();
        return null;
      } else {
        return token;
      }
    },

    getCurrentUser () {
      return current_user;
    },

    getUserType () {
      return user_type;
    },

    getPartnerName () {
      return partner_name;
    },

    getPartnerType () {
      return partner_type;
    },

    destroyToken () {
      localStorage.removeItem('token');
      localStorage.removeItem('token_expiration');
      localStorage.removeItem('user_type');
      localStorage.removeItem('partner_name');
    },

    isAuthenticated () {
      return !!this.getToken();
    },

    isAdmin () {
      return this.getUserType() === 'admin' || this.getUserType() === 'altibbi'
    },

    isSuperPharmacist () {
      return this.getUserType() === 'super pharmacist'
    },

    isPharmacist () {
      return this.getUserType() === 'pharmacist'
    },

    isAgent () {
      return this.getUserType() === 'agent'
    },

    getEnabledFeatures () {
      return enabled_features;
    },

    isFeatureEnabled (to) {
      return this.getEnabledFeatures().includes(to.meta.featureName);
    },

    getNotificationsCount () {
      return notifications_count;
    },
    decrementNotificationCount () {
      notifications_count -= 1;
    }

  };

  Object.defineProperties(Vue.prototype, {
    $auth: {
      get () {
        return Vue.auth;
      }
    }
  })
}
